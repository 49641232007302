import {
  Flex,
  Greyscale,
  ScreenSize,
  SecondaryColor,
  Typography,
} from 'glints-aries';
import { Divider as AriesDivider } from 'glints-aries/lib/@next';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { BookmarkButton } from 'src/modules/Bookmark';
import { Props as BookmarkButtonProps } from 'src/modules/Bookmark/BookmarkButton';

const { Paragraph } = Typography;

const LoadableCompanyLogo = dynamic(
  () => import('src/components/GlintsPicture/CompanyLogo')
);

export const Anchor = styled.a`
  -webkit-tap-highlight-color: transparent;
`;

export const CompanyName = styled(Paragraph).attrs({
  variant: 'regular',
  ellipsis: true,
})`
  margin-bottom: 5px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial;
`;

export const LocationName = styled(Paragraph).attrs({
  variant: 'caption',
  color: Greyscale.grey,
  ellipsis: true,
})``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 4px;
  border: 1px solid ${Greyscale.lightgrey};

  &:hover {
    ${CompanyName} {
      color: ${SecondaryColor.actionblue};
      transition: color 0.1s;
    }

    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-color: ${Greyscale.grey};
    cursor: pointer;
    transition: all 0.1s;
  }
`;

export const HeaderWrapper = styled.div`
  padding: 16px 16px 17px 16px;
  width: 100%;
  background: linear-gradient(180deg, #e7f1fa 0%, #e7f1fa60 100%);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const CompanyLogo = styled(LoadableCompanyLogo)`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  object-fit: contain;

  @media (min-width: ${ScreenSize.tablet}px) {
    width: 60px;
    height: 60px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  margin-right: 10px;
`;

export const ShieldAndCompanyName = styled(Flex)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  position: relative;

  img,
  && svg {
    flex-shrink: unset;
    flex-basis: 18px;
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
  }
`;

export const Bookmark = styled(BookmarkButton)<BookmarkButtonProps>`
  height: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoRow = styled(Row)`
  margin-bottom: 5px;
  font-size: 14px;

  svg {
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: ${Greyscale.grey};
  }
`;

export const IndustryRow = styled(InfoRow)`
  color: ${Greyscale.black};
`;

export const IndustryName = styled(Paragraph).attrs({
  variant: 'caption',
  ellipsis: true,
})``;

export const IconInfoRow = styled(InfoRow)`
  color: ${Greyscale.grey};

  a {
    color: ${SecondaryColor.actionblue};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ActiveTimeRow = styled(Row)`
  margin-top: auto;
  font-size: 12px;
  color: #646464;

  svg {
    margin-right: 5px;
  }
`;

export const Divider = styled(AriesDivider)`
  margin: 16px 0;
`;

export const JobList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
  a {
    margin-top: auto;
  }
`;

export const JobCard = styled.div`
  color: ${Greyscale.black};
`;

export const JobHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const JobTitle = styled(Paragraph).attrs({
  variant: 'caption',
})`
  font-weight: 600;
`;

export const SalaryWrapper = styled.span`
  white-space: nowrap;
  color: ${SecondaryColor.actionblue};
  font-weight: 600;
`;

export const NotDisclosedMessage = styled.span`
  color: ${Greyscale.grey};
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 8px 0;
`;

export const CTAText = styled(Paragraph).attrs({
  color: SecondaryColor.actionblue,
})`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0;
`;

export const CompaniesList = styled.ul`
  margin: 0;
  padding: 0;
`;
