import { gql } from '@apollo/client';

export const getSimilarCompanies = gql`
  query getSimilarCompanies($id: String!, $jobsData: GetJobsByCompanyInput) {
    getSimilarCompanies(id: $id) {
      id
      name
      logo
      IndustryId
      status
      isVIP
      city {
        name
      }
      country {
        name
      }
      hierarchicalLocation: location {
        name
        formattedName
        parents {
          formattedName
          name
          level
        }
      }
      industry {
        name
      }
      jobs(data: $jobsData) {
        id
        title
        shouldShowSalary
        type
        minYearsOfExperience
        maxYearsOfExperience
        educationLevel
        skills {
          skill {
            name
          }
        }
        salaries {
          minAmount
          maxAmount
          CurrencyCode
          salaryType
        }
      }
    }
  }
`;
