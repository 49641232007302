import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, Typography } from 'glints-aries/lib/@next';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'src/common/routes';
import { Company } from 'src/global/models/Company';
import { JobStatus } from 'src/global/models/Job';

import * as S from '../../CompanyPage.sc';
import { getSimilarCompanies } from '../../graphql';
import { CompanyCard } from './CompanyCard';
import * as Styled from './styles.sc';

interface Props {
  companyId?: string;
  countryCode?: string;
}

export const SimilarCompaniesSection: React.FC<
  React.PropsWithChildren<Props>
> = ({ companyId, countryCode }) => {
  const router = useRouter();
  const { data: similarCompaniesData } = useQuery<{
    getSimilarCompanies: Company[];
  }>(getSimilarCompanies, {
    variables: {
      id: companyId ?? router.query.id,
      jobsData: {
        status: JobStatus.OPEN,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const similarCompanies = similarCompaniesData?.getSimilarCompanies;

  const goCompaniesPage = () =>
    router.push({
      pathname: `/${ROUTES.companies}`,
      query: {
        countries: countryCode,
      },
    });

  return (
    <Styled.CompaniesList>
      <Choose>
        <When condition={similarCompanies && similarCompanies?.length > 0}>
          <S.CardContainer>
            {similarCompanies?.map((company, index) => (
              <CompanyCard key={company.id} index={index} company={company} />
            ))}
          </S.CardContainer>
        </When>
        <Otherwise>
          <S.EmptyCardContainer>
            <Typography variant="subtitle1">
              <FormattedMessage
                id="company-page.similar-companies-section.empty"
                defaultMessage="There are no similar companies at the moment"
              />
            </Typography>
            <Button onClick={goCompaniesPage}>
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-explore-more-companies"
                  defaultMessage="Explore More Companies"
                />
              </Typography>
            </Button>
          </S.EmptyCardContainer>
        </Otherwise>
      </Choose>
    </Styled.CompaniesList>
  );
};
