import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';

import { JobInterface } from 'src/modules/Opportunity/interface';

import * as Styles from './styles.sc';

interface Props {
  opportunity: JobInterface;
}

function formatCurrencyCode(code: string) {
  switch (code) {
    case 'IDR':
      return 'Rp';
    case 'VND':
      return '₫';
    case 'TWD':
      return 'NT$';
    default:
      return code;
  }
}

export const JobCardSalary: React.FC<Props> = ({ opportunity }) => {
  const intl = useIntl();

  const basicSalary = opportunity.salaries
    ? opportunity.salaries.find((salary) => salary.salaryType === 'BASIC')
    : null;

  if (!isEmpty(basicSalary) && opportunity?.shouldShowSalary) {
    const { minAmount, maxAmount, CurrencyCode } = basicSalary;

    const currencyCode = formatCurrencyCode(CurrencyCode);
    const formattedMinAmount = intl
      .formatNumber(minAmount, {
        notation: 'compact',
      })
      .replace(/\s/g, '');
    const formattedMaxAmount = intl
      .formatNumber(maxAmount, {
        notation: 'compact',
      })
      // Replace all whitespace with empty string
      .replace(/\s/g, '');

    return (
      <Styles.SalaryWrapper key={`${currencyCode}-${minAmount}-${maxAmount}`}>
        {`${currencyCode}${formattedMinAmount}`}
        <If condition={Boolean(maxAmount)}>
          {'-'}
          {`${currencyCode}${formattedMaxAmount}`}
        </If>
      </Styles.SalaryWrapper>
    );
  } else {
    return (
      <Styles.NotDisclosedMessage>
        <FormattedMessage
          id="job.card.salary.estimate.not.disclosed"
          defaultMessage="Not Disclosed"
        />
      </Styles.NotDisclosedMessage>
    );
  }
};
