import { get, identity, orderBy } from 'lodash-es';

import { Company } from 'src/global/models';

export const useSimilarCompanyLocation = (company: Company): string => {
  if (company) {
    const location = company.hierarchicalLocation;
    if (location) {
      try {
        return [
          location.formattedName,
          ...orderBy(location.parents, 'level', 'desc').map(
            (item) => item.formattedName
          ),
        ]
          .filter(identity)
          .join(', ');
      } catch (_error) {
        console.error(_error);
        return '';
      }
    }
    const cityName = get(company, 'city.name');
    const countryName = get(company, 'country.name');
    return cityName ? `${cityName}, ${countryName}` : countryName;
  }
  return '';
};
